.app{
  display: flex;
  flex-direction: column;
  width: 400px;
  margin: 100px auto;
  border: 1px solid red;
  padding: 20px;
  text-align: center;
}

.counter{
  display: flex;
  justify-content: space-around;
}

button {
  background-color: #4CAF50; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

p {
  padding: 10px;
  border-bottom: 2px dashed black;
}